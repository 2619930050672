import Quagga from 'quagga';
import React from 'react';

export class QuaggaScanner {

    private readerInitialized = false;
    private matchesByCodigo: { [key: string]: number } = {};
    private waitingForFindActionToComplete = false;

    private constructor(
        target: React.RefObject<HTMLDivElement>,
        private onFind: (code: string) => Promise<void>,
        setFlippable: (flippable: boolean) => void,
        setFlipHandler: (flipHandler: { handler: () => void }) => void,
        private idCamara: string,
        private idCamaraAlternativa?: string
    ) {
        if (this.idCamaraAlternativa) {
            setFlippable(true);
            setFlipHandler({
                handler: () => {
                    const newIdCamara = this.idCamaraAlternativa!;
                    this.idCamaraAlternativa = this.idCamara;
                    this.idCamara = newIdCamara;
                    this.iniciarReader(target);
                }
            });
        }
        this.iniciarReader(target)
    }

    static async newScanner(
        target: React.RefObject<HTMLDivElement>,
        onFind: (code: string) => Promise<void>,
        setFlippable: (flippable: boolean) => void,
        setFlipHandler: (flipHandler: { handler: () => void }) => void,
    ): Promise<QuaggaScanner> {
        await this.pedirPermisoCamaras();
        const { traseras, frontales } = await this.detectarCamaras();
        const deviceId = traseras?.length ? traseras[0] : frontales?.length ? frontales[0] : null;
        if (deviceId === null) {
            throw new Error('No hay cámaras para instanciar un escáner');
        }
        let deviceIdAlt = frontales?.length ? frontales[0] : traseras?.length ? traseras[0] : undefined;
        if (deviceIdAlt === deviceId) {
            deviceIdAlt = undefined;
        }
        return new QuaggaScanner(target, onFind, setFlippable, setFlipHandler, deviceId, deviceIdAlt);
    }

    private static async pedirPermisoCamaras() {
        let constraints = {
            video: {
                width: 640,
                height: 480
            },
            audio: false
        };

        let stream = await navigator.mediaDevices.getUserMedia(constraints);
        stream.getTracks().forEach(s => s.stop());
    }

    private static async detectarCamaras(): Promise<{ traseras?: string[], frontales?: string[] }> {
        let camaras = await navigator.mediaDevices.enumerateDevices();
        const traseras = [];
        const frontales = [];
        let ids = camaras.filter(d => d.kind === 'videoinput').map(d => d.deviceId);

        for (const id of ids) {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: id } } });
            let facing = stream.getVideoTracks()[0].getSettings().facingMode;
            if (facing === 'user') {
                frontales.push(id);
            } else {
                traseras.push(id);
            }
            stream.getTracks().forEach(s => s.stop());
        }
        if (traseras.length > 0 && frontales.length > 0) {
            return { traseras, frontales };
        } else if (traseras.length > 0) {
            return { traseras };
        } else if (frontales.length > 0) {
            return { frontales };
        }
        return {};
    }

    private iniciarReader(target: React.RefObject<HTMLDivElement>) {
        if (this.readerInitialized) {
            if (target.current) {
                target.current.innerHTML = '';
            }
            Quagga.offDetected();
            Quagga.stop();
            this.readerInitialized = false;
        }
        Quagga.init({
            inputStream: {
                type: "LiveStream",
                target,
                constraints: {
                    deviceId: {
                        exact: this.idCamara
                    }
                }
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
            numOfWorkers: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 4),
            decoder: {
                readers: [
                    {
                        format: "upc_reader",
                        config: {}
                    },
                    {
                        format: "ean_reader",
                        config: {}
                    }
                ]
            },
            locate: true
        }, (err: string) => {
            if (err) {
                throw new Error(err);
            }
            Quagga.onDetected((data: { codeResult: { code: string } }) => {
                if (this.waitingForFindActionToComplete) {
                    return;
                }
                if (!this.matchesByCodigo[data.codeResult.code]) {
                    this.matchesByCodigo[data.codeResult.code] = 0;
                }
                this.matchesByCodigo[data.codeResult.code]++;
                if (this.matchesByCodigo[data.codeResult.code] >= 10) {
                    this.waitingForFindActionToComplete = true;
                    this.onFind(data.codeResult.code)
                        .finally(() => this.waitingForFindActionToComplete = false);
                    this.matchesByCodigo = {};
                }
            });
            Quagga.start();
            this.readerInitialized = true;
        });
    }

    public stop() {
        Quagga.stop();
    }


}
