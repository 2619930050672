import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Articulo } from "../../../shared/model/articulo.model";
import { getConfig } from "../../utils/config";
import { FullscreenSpinner } from "../fullscreen-spinner/fullscreen-spinner.component";
import { Modal } from "../modal/modal.component";
import { TimeoutProgressBar } from "../timeout-progressbar/timeout-progressbar.component";

export function ViewArticulo() {
  const { ean } = useParams();
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(0);
  const [error, setError] = useState<string>();
  const [titulo, setTitulo] = useState(`Artículo ${ean}`);
  const [articulo, setArticulo] = useState<Articulo>();
  const navigate = useNavigate();


  useEffect(() => {
    async function getArticulo() {
      const timeout = (await getConfig())?.timeoutArticulo || 5000;
      setTime(timeout);
      const response = await fetch(`/api/art/${ean}`);
      if (response.status !== 200) {
        const error = await response.text();
        setError(error);
      } else {
        const art = await response.json();
        setArticulo(art);
        setTitulo(`[${art.codigo.trim()}] ${art.descripcion}`);
      }
      setLoading(false);
    }
    getArticulo();
  }, [ean]);


  function handleClose() {
    navigate('/');
  }

  return <>
    {loading && <FullscreenSpinner />}
    <Modal title={titulo} onClose={handleClose} useFooter={false}>
      {articulo ? (
        <>
          <div className="flex p-1">
            <p className="font-bold">Stock:&nbsp;</p>
            <p>{articulo.stock}</p>
          </div>
          <div className="flex p-1">
            <p className="font-bold">Precio:&nbsp;</p>
            <p>${articulo.precio.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
          </div>
        </>
      ) : (
        <>
          {!!error && <p>
            <FontAwesomeIcon className="mr-2" icon={faCircleXmark} />
            {error}.
          </p>}
        </>
      )}
      <div className="mt-5">
        <TimeoutProgressBar onFinish={handleClose} milliseconds={time} />
      </div>
    </Modal>
  </>
}