import React from "react";
import './barcode-scanner.scss';
import { QuaggaScanner } from "./quagga-scanner";

export interface BarcodeScannerProps {
  onStart?: (couldStart: boolean) => void;
  onFind: (code: string) => Promise<void>;
  setFlippable: (flippable: boolean) => void;
  setFlipHandler: (flipHandler: { handler: () => void }) => void;
  setLoading: (loading: boolean) => void;
}

export class BarcodeScanner extends React.Component<BarcodeScannerProps, { scanner : QuaggaScanner | null }> {

  private scannerTarget: React.RefObject<HTMLDivElement>;

  constructor(props: BarcodeScannerProps) {
    super(props);
    this.scannerTarget = React.createRef();
    this.state = {
      scanner: null
    };
  }

  componentDidMount(): void {
    this.props.setLoading(true);
    QuaggaScanner.newScanner(this.scannerTarget, this.props.onFind, this.props.setFlippable, this.props.setFlipHandler)
      .then(scanner => {
        this.props.onStart?.(true);
        this.setState({ scanner });
      }).catch(() => {
        this.props.onStart?.(false);
      }).finally(() => {
        this.props.setLoading(false);
      });
  }

  componentWillUnmount(): void {
    this.state.scanner?.stop?.();
  }

  shouldComponentUpdate(): boolean {
    return false;
  }

  render() {
    return (
      <div className="flex justify-center items-center">
        <div id="interactive" className="viewport" ref={this.scannerTarget}></div>
      </div>
    );
  }

}
