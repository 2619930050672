import { faBarcode, faCameraRotate, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sleep } from "../../utils/utils";
import { Banner } from "../banner/banner.component";
import { BarcodeScanner } from "../barcode-scanner/barcode-scanner.component";
import { FullscreenSpinner } from "../fullscreen-spinner/fullscreen-spinner.component";

export function ViewScanner() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cameraGranted, setCameraGranted] = useState(false);
  const [flippable, setFlippable] = useState(false);
  const [search, setSearch] = useState('');
  const [flipHandler, setFlipHandler] = useState<{ handler: () => void }>({
    handler: () => {
      console.log('Not available');
    }
  });

  const searchDescriptionEnabled = useMemo(() => search?.trim(), [search]);
  const searchEanEnabled = useMemo(() => searchDescriptionEnabled && !isNaN(Number(search?.trim())), [search, searchDescriptionEnabled]);

  async function onFind(code: string) {
    handleEan(code);
    await sleep(1000);
  }

  function handleSearch() {
    navigate(`/art/matches?descripcion=${search}`);
  }

  function handleEan(ean: string) {
    navigate(`/art/${ean}`);
  }

  function handleEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      if (searchEanEnabled) {
        handleEan(search);
      } else {
        handleSearch();
      }
    }
  }

  const caption = 'Verifique su precio aquí';

  return <div className="view h-full">
    <Banner />
    <div className="p-4 pb-20 md:pb-4">
      {loading && <FullscreenSpinner />}
      <div className="flex flex-col h-full justify-center items-center">
        {!cameraGranted && <p id="scanner-nocam-verifique-caption" className="text-9xl text-white text-center m-10 w-full xl:w-1/2">
          {caption}
        </p>}
        <div className="flex flex-col items-end">
          {flippable && <button className="mb-2" id="flip-camera" onClick={() => { flipHandler.handler() }}>
            <FontAwesomeIcon icon={faCameraRotate} />
          </button>}
          {cameraGranted && <div id="scanner-verifique-caption" className="w-full flex justify-center mb-4">
            <p className="text-white">
              {caption}
            </p>
          </div>}
          <div className="max-h-50vh flex flex-col items-center overflow-hidden rounded-lg">
            <BarcodeScanner onStart={setCameraGranted} onFind={onFind}
              setFlipHandler={setFlipHandler} setLoading={setLoading} setFlippable={setFlippable} />
          </div>
          <div id="buscar-manual" className="w-full mt-2">
            <input id="busqueda-manual" type="text" tabIndex={0} autoFocus placeholder="Búsqueda manual"
              onChange={event => setSearch(event.target.value)} onKeyDown={handleEnter} />
            <div className="flex gap-2 mt-2 flex-col md:flex-row-reverse">
              <button id="btn-buscar-descripcion" disabled={!searchDescriptionEnabled} onClick={handleSearch}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                &nbsp;
                Buscar por descripción
              </button>
              <button id="btn-buscar-codigo" disabled={!searchEanEnabled} onClick={() => handleEan(search)}>
                <FontAwesomeIcon icon={faBarcode} />
                &nbsp;
                Buscar por código
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}