import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { ViewArticulo } from './components/view-articulo/view-articulo.component';
import { ViewMatches } from './components/view-matches/view-matches.component';
import { ViewScanner } from './components/view-scanner/view-scanner.component';
import './transition.scss';

function App() {

  return <>
    <ToastContainer />
    <Routes>
      <Route path="/" element={<ViewScanner />} />
      <Route path="/art/matches" element={<ViewMatches />} />
      <Route path="/art/:ean" element={<ViewArticulo />} />
    </Routes>
  </>
}

export default App;
