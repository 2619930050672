import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type ModalProps = {
  onClose: () => void,
  title: string,
  children: React.ReactNode
} & ({
  useFooter: false
} | {
  useFooter: true,
  footer: React.ReactNode
});

export function Modal({ onClose, title, children }: ModalProps) {

  return <div className="view">
    <div>
      <div className="fixed inset-0 z-40 flex items-center justify-center p-0 md:p-4">
        <div className="flex flex-col mx-auto overflow-hidden rounded-lg h-full w-full md:v-auto md:h-auto bg-white dark:bg-neutral-900 shadow-xl sm:w-full sm:max-w-xl">
          <div className="relative pl-6 pr-6 pt-6 flex flex-row items-center">
            <button type="button" onClick={onClose} className="static md:absolute -ml-4 md:ml-0 top-4 right-4 rounded-lg p-4 md:p-3 text-center font-medium text-neutral-500 transition-all hover:bg-neutral-200 dark:hover:bg-neutral-800 border-hidden shadow-none">
              <FontAwesomeIcon icon={faClose} />
            </button>
            <h3 className="text-lg font-bold text-neutral-900 dark:text-neutral-100">
              {title}
            </h3>
          </div>
          <div className="p-6 flex-grow md:flex-grow-0 overflow-auto text-sm text-neutral-500 dark:text-neutral-300">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
}