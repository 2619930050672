import { useEffect, useMemo, useState } from "react";
import { ProgressBar } from "../progress-bar/progress-bar.component";

export interface TimeoutProgressbarProps {
    milliseconds: number;
    onFinish: () => void;
}

export function TimeoutProgressBar({ milliseconds, onFinish } : TimeoutProgressbarProps) {
    
    const [iterations, setIterations] = useState(0);
    const iterationTime = useMemo(() => (milliseconds / 1000) ,[milliseconds]);
    const timePassed = useMemo(() => iterationTime * iterations, [iterations, iterationTime]);

    useEffect(() => {
        if (timePassed >= milliseconds) {
            onFinish();
        } else {
            setTimeout(() => {
                setIterations(iterations + 1);
            }, iterationTime);
        }
    }, [timePassed, milliseconds, onFinish, iterations, iterationTime, setIterations])
    
    return <ProgressBar size="thin" progress={100 - (iterations / 10)} /> 
}