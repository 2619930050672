import { Spinner } from '../spinner/spinner.component';

import './fullscreen-spinner.scss';

export interface FullScreenSpinnerProps {
  title?: string;
  description?: string;
}

export function FullscreenSpinner({ title, description }: FullScreenSpinnerProps) {

  const spinnerTitle: string = title || "Cargando";
  const showDescription: boolean = !!description;

  return <div className="spinner-dialog z-50">
    <Spinner />
    <div className="spinner-title">
      {spinnerTitle}
    </div>
    {showDescription && <div className="spinner-description">
      {description}
    </div>}
  </div>

}