import { faArrowRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ArticuloMatch } from "../../../shared/model/articulo-match.model";
import { FullscreenSpinner } from "../fullscreen-spinner/fullscreen-spinner.component";
import { Modal } from "../modal/modal.component";

export function ViewMatches() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [matches, setMatches] = useState<ArticuloMatch[]>([]);
  const descripcion = useMemo(() => searchParams.get('descripcion')?.trim(), [searchParams]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getMatches() {
      const response = await fetch(`/api/art/matches?descripcion=${descripcion}`);
      if (response.status !== 200) {
        const error = await response.text();
        setError(error);
      } else {
        setMatches(await response.json());
      }
      setLoading(false);
    }
    getMatches();
  }, [descripcion]);

  function handleClose() {
    navigate('/');
  }

  return <>
    {loading && <FullscreenSpinner />}
    <Modal title={`Resultados de búsqueda para [${descripcion}]`} onClose={handleClose} useFooter={false}>
      <div className="md:max-h-50vh overflow-y-auto">
        {matches.length ? (
          <ul className="divide-y divide-neutral-200 dark:divide-neutral-600">
            {matches.map(({ codigo, descripcion }) => (
              <li className="flex" key={codigo.trim()}>
                <Link className="p-4 w-full h-full hover:bg-neutral-100 dark:hover:bg-neutral-800" 
                    to={`/art/${codigo.trim()}`}>
                  <div className="flex flex-row items-center">
                    <p className="flex-grow">
                      [{codigo.trim()}] {descripcion}
                    </p>
                    <p className="text-neutral-300 p-3">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <>
            <p>
              No se encontraron resultados.
            </p>
            {!!error && <p><FontAwesomeIcon icon={faCircleXmark} />&nbsp;{error}.</p>}
          </>
        )}
      </div>
    </Modal>
  </>
}