
const sizes = {
    'ultra-thin': 'h-px',
    'thin': 'h-1',
    'normal': 'h-2',
    'thick': 'h-4'
};

export type ProgressBarSize = 'ultra-thin' | 'thin' | 'normal' | 'thick';

export function ProgressBar({ progress, size }: { progress: number, size?: ProgressBarSize }) {

    const theSize = size ?? 'normal';

    return <div className={`relative flex ${sizes[theSize]} w-full overflow-hidden rounded-full bg-secondary-200 dark:bg-neutral-800`}>
        <div style={{ width: `${progress}%` }} className="flex h-full bg-neutral-800 dark:bg-neutral-400"></div>
    </div>

}